import React, { useEffect } from "react";
import Header from "../../components/Header";
import HomeButton from "../../components/HomeButton";
import ItemPLP from "../ItemPLP/ItemPLP";
import SignIn from "./SignIn";

function Home() {
  const renderHomeButtons = () => {
    const menuList = [
      "Import Supplier File",
      "Import Existing Items",
      "View Item List",
      "Import Images",
    ];
    return menuList.map((item) => (
      <HomeButton
        ButtonText={item}
        LinkTo={item.replaceAll(" ", "")}
      />
    ));
  };

  const renderHomePage = () => {
    switch (sessionStorage.getItem("issignedin") || "0") {
      case "1":
        // return (
        //   <>
        //     {/* <Header headerTitle="Toymate Portal" /> */}
        //     <div className="default-alignment">{renderHomeButtons()}</div>
        //   </>
        // );
        return <ItemPLP />;
      case "0":
        return <SignIn />;
    }
  };

  return <>{renderHomePage()}</>;
}

export default Home;
