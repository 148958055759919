import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay, Button } from "react-bootstrap";
import { SetStateAction } from "react";

interface ProductViewOverlayProps {
  show: boolean;
  targetRef: any;
  ProductView: string;
  setProductView: React.Dispatch<SetStateAction<string>>;
}

export const ProductViewOverlay = ({
  show,
  targetRef,
  ProductView,
  setProductView,
}: ProductViewOverlayProps) => {
  const renderViewList = () => {
    const arrList = [
      { Title: "Large Grid", Type: "large" },
      { Title: "Small Grid", Type: "small" },
      { Title: "List View", Type: "list" },
    ];

    return arrList.map((list, index) => (
      <div
        key={index}
        className="overlay-list btnHover"
        onClick={() => setProductView(list.Type)}
      >
        {list.Title} {ProductView === list.Type && <FontAwesomeIcon icon={solid("check")} />}
      </div>
    ));
  };

  return (
    <Overlay
      target={targetRef.current}
      show={show}
      placement="bottom"
      containerPadding={20}
    >
      {({
        placement: _placement,
        arrowProps: _arrowProps,
        show: _show,
        popper: _popper,
        hasDoneInitialMeasure: _hasDoneInitialMeasure,
        ...props
      }) => (
        <div
          {...props}
          className="overlay-list-container"
        >
          {renderViewList()}
        </div>
      )}
    </Overlay>
  );
};
