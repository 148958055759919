import { ConvertToCurrency } from "../functions/ConvertToCurrency";
import NoImgAvail from "../assets/img/NoImgAvailable.jpg";

interface ItemCardLargeProps {
  ItemNo: string;
  ImgURL: string;
  Description: string;
  Price: number;
  InstockLW: number;
  SoldLWQty: number;
  SOHQty: number;
  CommittedWOC: number;
}

function ItemCardSmall({
  ItemNo,
  ImgURL,
  Description,
  Price,
  InstockLW,
  SoldLWQty,
  SOHQty,
  CommittedWOC,
}: ItemCardLargeProps) {
  return (
    <>
      {/* <div
   className="card-small-wrapper"
   onClick={() => {
     window.open(`/DetailsPage/${ItemNo}`, "_blank");
   }}
     > */}
      <div className="card-small-image-container default-alignment padding-0">
        {ImgURL.length === 0 ? (
          <img
            className="card-small-image"
            loading="lazy"
            src={NoImgAvail}
            alt="No image available"
          />
        ) : (
          <img className="card-small-image" loading="lazy" src={ImgURL} alt="No image available" />
        )}
      </div>
      <div className="card-small-container">
        <div
          className="card-small-title"
          onClick={() => {
            window.open(`/DetailsPage/${ItemNo}`, "_blank");
          }}
        >
          <p className="title">{Description}</p>
          <div>
            <p className="price">{ConvertToCurrency(Price)}</p>
            <p className="item-number">{ItemNo}</p>
          </div>
        </div>
        <div className="card-small-body-row">
          <div>
            <p>LW Stock</p>
            <p>{InstockLW}%</p>
          </div>
          <div>
            <p>SOH</p>
            <p>{SOHQty}</p>
          </div>
          <div>
            <p>LW Sales</p>
            <p>{SoldLWQty}</p>
          </div>
        </div>
        <div className="card-small-footer">
          <div>
            <p>Committed WOC {CommittedWOC}</p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
export default ItemCardSmall;
