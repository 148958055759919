import { useEffect } from "react";
import { useDebounce } from "usehooks-ts";

// to use this
// const [actualInput, setActualInput] = useState<string>("");
// useDebouncedState(actualInput, setDebouncedInput, 500);
export const useDebouncedState = (
  input: any,
  setDebouncedInput: React.SetStateAction<any>,
  debounceWaitTime: number
) => {
  const debouncedInput = useDebounce<any>(input, debounceWaitTime);

  useEffect(() => {
    setDebouncedInput(debouncedInput);
  }, [debouncedInput]);

  return input;
};
