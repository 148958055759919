import Spinner from "react-bootstrap/Spinner";

function LoadingComponent() {
  return (
    <Spinner
      animation='border'
      role='status'
    >
      <span className='hidden'>Loading...</span>
    </Spinner>
  );
}

export default LoadingComponent;
