import React, { useState, useEffect } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../../functions/RunFetch";
import Header from "../../components/Header";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { Form } from "react-bootstrap";

function SignIn() {
  const [UserID, setUserID] = useState("");
  const [Password, setPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [SignInResponse, setSignInResponse] = useState("");

  const sessionSignIn = (username: string, userid: string, issignedin: string) => {
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("userid", userid);
    sessionStorage.setItem("issignedin", issignedin);
  };

  const handleClick_LogIn = async () => {
    // create inputJSON for sp
    const inputJSON: string = JSON.stringify({
      UserID: UserID,
      Password: Password,
    });

    const res = await runFetch(spURL_POST + "Portal_User_Verify", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setSignInResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);

      const username = spResponse.Table[0].UserName;
      const userid = spResponse.Table[0].UserID;

      if (isCheckboxChecked) {
        localStorage.setItem("staysignedin", "1");
        localStorage.setItem("username", username);
        localStorage.setItem("userid", userid);
      }

      sessionSignIn(username, userid, "1");
      window.location.reload();
    }
  };

  useEffect(() => {
    setSignInResponse("");
  }, [UserID, Password, isCheckboxChecked]);

  useEffect(() => {
    if (localStorage.getItem("staysignedin") === "1") {
      const username: string = localStorage.getItem("username")!;
      const userid: string = localStorage.getItem("userid")!;

      if (username === "" || userid === "") {
        localStorage.setItem("staysignedin", "0");
        sessionSignIn("", "", "0");
      } else {
        sessionSignIn(username, userid, "1");
        window.location.reload();
      }
    }
  }, []);

  return (
    <>
      {/* <Header headerTitle="Sign-In" /> */}
      <div
        onKeyDown={(e) => {
          OnEnterKeyDown(e, handleClick_LogIn);
        }}
      >
        <div className="handheld-body-container">
          <table className="signin-table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <p>
                      <strong>UserID:</strong>
                    </p>
                    <input
                      id="signin-userID"
                      name="UserID"
                      onChange={(e) => handleChange_Input(e, setUserID)}
                    ></input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p>
                      <strong>Password:</strong>
                    </p>
                    <input
                      id="signin-password"
                      type="text"
                      className="form-password"
                      name="Password"
                      onChange={(e) => handleChange_Input(e, setPassword)}
                    ></input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <button className="signin-login-button btnHover" onClick={handleClick_LogIn}>
                    Log In
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Stay signed in?`}
            checked={isCheckboxChecked}
            className="checkbox-row"
            onChange={(e) => setIsCheckboxChecked(e.target.checked)}
          />
          <p className="error-message" id="SignInResponse">
            {SignInResponse}
          </p>
        </div>
      </div>
    </>
  );
}

export default SignIn;
