import React from "react";
import Header from "../../components/Header";

function NotFound() {
  return (
    <>
      <p>ERROR 404: Page not found.</p>
    </>
  );
}

export default NotFound;
