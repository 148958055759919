import React, { ChangeEvent, Component, SetStateAction, useState } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import { Button, FormCheck } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ConvertToCurrency } from "../functions/ConvertToCurrency";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import Accordion from "react-bootstrap/Accordion";

type PopupProps = {
  inputJSON: string;
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
};

interface SummaryMainObj {
  CF: number;
  QUIT: number;
  "Price Point (0-10)": number;
  "Price Point (10-20)": number;
  "Price Point (20-30)": number;
  "Price Point (30-50)": number;
  "Price Point (50-100)": number;
  "Price Point (100+)": number;
  "Margin Band (Negative)": number;
  "Margin Band (0-25)": number;
  "Margin Band (25-40)": number;
  "Margin Band (40-55)": number;
  "Margin Band (55+)": number;
  "Competitor (No Competitor)": number;
  "Competitor (20% + Cheaper)": number;
  "Competitor (0-20 % Cheaper)": number;
  "Competitor (0-20 % More)": number;
  "Competitor (20-40 % More)": number;
  "Competitor (40% + More)": number;
  "Private Label": number;
  "Not Private Label": number;
  "SOH Cover (0-4)": number;
  "SOH Cover (4-8)": number;
  "SOH Cover (Normal)": number;
  "SOH Cover (52+)": number;
  "L4W Instock (Never Stocked)": number;
  "L4W Instock (0-25%)": number;
  "L4W Instock (25-50%)": number;
  "L4W Instock (50-75%)": number;
  "L4W Instock (75% +)": number;
  "RR Forecast Retail": number;
  "Trading Forecast Retail": number;
  "Rolling Year Sales": number;
  "H2H LY Sales": number;
  "LW Sales": number;
  "L4W Sales": number;
  "SOH Retail": number;
  "SOO Retail": number;
  "Committed Retail": number;
  "Commit Stockturn of RR Forecast Retail": number;
  "Commit Stockturn on Rolling Year Sales": number;
  "Commit Coverage of H2H Sales Plus MD(10%)": number;
}

interface SummaryCatObj {
  Category: string;
  "Review Forecast$": number;
  "Trading Forecast$": number;
  "H2H Review Forecast$": number;
  "H2H Trading Forecast$": number;
  SOH$: number;
  SOO$: number;
  Commit$: number;
}

interface SupplierQuoteObj {
  Image: string;
  Link: string;
  Description: string;
  "Item Lookup Code": string;
  "Reorder No": string;
  "USD Cost": number;
  "Last PO Cost": number;
  SOH: number;
  OO: number;
  MPQ: number;
  Buyer: string;
  Dept: string;
  Cat: string;
}

interface TopItemsObj {
  Image: string;
  Link: string;
  ItemNo: string;
  Description: string;
  Category: string;
  ItemType: string;
  "Range Status": string;
  Supplier: string;
  "Reorder No": string;
  Price: string;
  "Avg Sell": string;
  "Qty Sold Last 7 Days": number;
  "Qty Sold Yesterday": number;
  Sales: string;
  Margin: string;
  "Margin%": string;
  SOH: number;
  SOO: number;
  Buyer: string;
  MPQ: number;
  "LW Instock%": string;
}

interface StockStatusObj {
  Image: string;
  Link: string;
  "Item Code": string;
  Description: string;
  "Supplier Buying Manager": string;
  Buyer: string;
  Department: string;
  Category: string;
  SubCategory: string;
  Segment: string;
  ItemSource: string;
  RangeStatus: string;
  ItemType: string;
  "Item Planning": string;
  "Season Code": string;
  QuitCycle: string;
  "Previous Catalogue": string;
  "Current Catalogue": string;
  "Next Catalogue": string;
  "In Season": string;
  "Hot Xmas Line": string;
  "Target Line Instock Instore at Xmas": string;
  "Evergreen Status": string;
  "Web Assortment": string;
  "Number of Barcodes Loaded": number;
  "Number of Barcodes Sold": number;
  "Battery Type": string;
  "Age Grading": string;
  "Picking Group": string;
  "Supplier Group": string;
  "Supplier Account": string;
  "Showroom or Brand Owner": string;
  Licensor: string;
  "Vendor Item Number": string;
  "Previous Foreign Cost": number;
  "Current Foreign Cost": number;
  "Ship Region": string;
  Port: string;
  "Calculated Landed Cost": number;
  "Supplier List Cost": number;
  "Last PO Cost": number;
  "Average Cost": number;
  Price: number;
  "GP%": number;
  "Closeout Price": number;
  "Avg Sell Price LW": number;
  "Avg Sell Price L4W": number;
  "Avg Sell Price L13W": number;
  "Avg Price LW Online": number;
  "Buisness Promo Price": number;
  MSRP: number;
  "Lowest Store Promo Price": number;
  "Price Point": string;
  "Available Date": string;
  "Is Item on Preorder": string;
  "Days Until Earliest Available": string;
  "Suitable for Preorder": string;
  "Effective Carton CBM": number;
  "Outer Ctn Qty": number;
  "Inner Ctn Qty": number;
  "Effective Item CBM": number;
  "CBM Type": string;
  "Effective Item Size": string;
  "Box Size Type": string;
  MOQ: number;
  "Number of Stores In Grade": number;
  Grade: number;
  "Days Since First Sold": number;
  Age: string;
  "SOH Above $3000 in Store and No Sales LW": string;
  "Item Over 1 Year Old - No Stock Received": string;
  "Trading FC Stockturn Below 1": string;
  "Top 20 Line Commitment $ + Trading FC under 0.8": string;
  "Margin Outlier": string;
  "Under 25% Margin Last Week": string;
  "NPD Underperformer": string;
  "Top 250 SKUs in Retail Sales Inc": string;
  "Top 250 Feature in L13W": string;
  "Top 600 Feature in L13W": string;
  "How Many Top 250 Feature in L13": number;
  "Top 250 Improved Sellers": string;
  "Top250L13W Under Instock Threshold (75%)": string;
  "Action Taken to Improve Instock": string;
  "Top 20 Commitment by Buyer": string;
  "Markup Opportunity": string;
  "Markdown Opportunity": string;
  "Needs Reorder": string;
  "Price Above 15% Lead": string;
  "Price Above 30% Lead": string;
  "Suggested New Price": number;
  "Calculated Markdown Value at New Price": number;
  "Season Big Bets": string;
  "Supplier Market Quota": number;
  "Supplier Toymate Buy Plan": number;
  "Supplier Buy Plan RRP": number;
  "Toymate Buy Plan": number;
  "July/Aug Bulk Qty": number;
  "Xmas Bulk Qty": number;
  "Retail $ LW": number;
  "LW GP $": number;
  "LW GP %": number;
  "Qty Sold LW": number;
  "L4W Qty Sold": number;
  "L13W Qty Sold": number;
  "Commit Pcs": number;
  "Total SOH Stores": number;
  "Total SOH Stores + DC": number;
  "Total SOO Stores + DC": number;
  "Total SOO Pcs Due": number;
  "Total SOO 0-30": number;
  "Total SOO 30-60": number;
  "Total SOO 60-90": number;
  "Total SOO 90+": number;
  "Total PO Stores": number;
  "Total PO Qty Warehouse": number;
  "Supplier Hold QTY": number;
  "SOH Warehouse Available": number;
  "SOH Warehouse On Transfer": number;
  "SOH Warehouse": number;
  "Intertrading Pallets": number;
  "Total Available Intertrading": number;
  "Total Intertrading SSCC": number;
  "Total SOH Intertrading": number;
  "WH Difference Pcs": number;
  "WH Difference Cost": number;
  "Total SOH Stores Cost$ Ex GST": number;
  "Total SOH Stores Retail$ Inc GST": number;
  "Total SOO Cost$": number;
  "Total SOO Retail$": number;
  "Total Committed Cost$": number;
  "Total Committed Retail$": number;
  "Total Forward Weeks Cover SOH": number;
  "Total Forward Weeks Cover Committed": number;
  "Best WK Rolling 2 Year": number;
  "2nd Best WK Rolling 2 Year": number;
  "Best WK Rolling 1 Year": number;
  "2nd Best WK Rolling 1 Year": number;
  "Best WK Sales 52W": number;
  "Best WK Sales 13W": number;
  "Best WK Sales 8W": number;
  TradingStatus: number;
  "52WK Forecast": number;
  "13 WK Forecast": number;
  "Saved Forecast": number;
  "Trading Forecast": number;
  "Trading Forecast$": number;
  "Counted on RR Summary": string;
  "Line Order of RR": number;
  "Range Review Forecast": number;
  "Range Review Forecast at Retail $": number;
  UPSPW: number;
  "Current H2H Forecast Units": number;
  "Current H2H Forecast Retail $": number;
  "Historical Forecast": number;
  "Number of Stores OOS": number;
  "MAX L4W Instock%": number;
  "LW Instock%": number;
  "LW Instock Committed%": number;
  "LW Qty Sold": number;
  "LW-1 Qty Sold": number;
  "LW-2 Qty Sold": number;
  "LW-3 Qty Sold": number;
  "LW-4 Qty Sold": number;
  "YTD Qty Sold": number;
  "Rolling Year Qty Sold": number;
  "LY Qty Sold": number;
  "LY H2H Qty Sold": number;
  "LW Retail $": number;
  "YTD Retail $": number;
  "Rolling Year Retail $": number;
  "LY Retail $": number;
  "LY H2H Retail $": number;
  "Is Item Listed": string;
  "Image Loaded": string;
  "Auburn SOH": number;
  "Auburn Committed": number;
  "Web Buffer": number;
  "Online SOH": number;
  "Online Forecast 52 Weeks": number;
  "Online Forecast 13 Weeks": number;
  "Online Forecast": number;
  "Trading Forecast Online": number;
  "LW Qty Sold Online": number;
  "LW Retail Online $": number;
  "Online % of Sales": number;
  "Site Visits LW": number;
  "LW Cost of Marketing as % of Sales Online": number;
  "LW Cost of Marketing as % of Sales Buisness": number;
  "L4W Retail Online $": number;
  "L4W Qty Sold Online": number;
  "Online % of Sales L4W": number;
  "Site Vistis L4W": number;
  "Google visits L4W": number;
  "L4W Cost of Marketing as % of Sales Online": number;
  "L4W Cost of Marketing as % of Sales Buisness": number;
  "Web Traffic Ranking L4W": number;
  "Position Last Week": number;
  "Curent Positon": number;
  "Lead Competitor Name": string;
  "Lead Competitor Price": number;
  "Competitive Detail TW": string;
  "% Diff Lead Price TW": number;
  "# Price Matches LW": number;
  "Avg Price for Price Matches LW": number;
  "Trading Forecast Stockturn SOH": number;
  "Trading Forecast Stockturn Committed SOH": number;
  "Price History (Ex Promo)": string;
  "Last Price Change Reason": string;
  "Days Since Last Price Change": number;
  "Markdown Forecast Since Last Price Change": number;
  "Latest Price Change Stockturn SOH": number;
  "Next 8 Weeks Sell Through": number;
  "Season Sell Through": number;
  "Sellout Month": string;
  "% Sales Since Last Price Change": number;
  "% Margin Since Last Price Change": number;
  "Last Promo Price": number;
  "Promo Sales Uplift %": number;
  "Promo GP Uplift %": number;
  "Past Season Markdown Flag": string;
  "Current Season Markdown Flag": string;
  "Supplier Line Performance Indicator": string;
  "Vendor Name": string;
  "Smyths Forecast": number;
  "52WK max retail": number;
  "Smyths USD": number;
  "Retail Pounds": number;
  "YTD Faulty Returns": number;
  "LY Faulty Returns": number;
  "Rolling Year Faulty Returns (L52W)": number;
  "Prior Rolling Year Faulty Returns (53W-104W)": number;
  "Out of Box": string;
  "Merch Spot": string;
  "Lead Sale Status": string;
  OHS: string;
  "Item Category Code": string;
  "Cat Min Margin %": number;
  "Cat Max Margin %": number;
  "Cost or Margin Issue": string;
  "CBM Issue": string;
  "Item Size": string;
  "Item Size as Per Priced Based CBM": number;
  "% 1 Facing Takes up of Bay": number;
  "Instock Stock required (15WOC)": number;
  "Instock Stock Required CBM": number;
  "Maximum Between Ctn and Instock CBM": number;
  "Maximum as a Bay Number": number;
  "Facings Sales Multiplier": number;
  "No of Facings Taken": number;
  "Max Units on Shelf": number;
  "Item Size Sales Criteria": number;
  "Meets Criteria": string;
  "Total Space on Bays Taken": number;
  "Sales per Bay inc GST": number;
  "Margin $ Per Bay": number;
  "Stock Holding WOC Check": number;
  "Stock Holding WOC Issue (Above 30)": string;
  "NPD Reorder No Matches": number;
  "NPD Matched LineID": string;
  "NPD Manufacturer": string;
  "NPD Supercategory": string;
  "NPD Property": string;
  "NPD UPSPW - LW": number;
  "NPD UPSPW - L4WK": number;
  "NPD UPSPW - L13WK": number;
  "NPD Full Year Sales Forecast - LW": number;
  "NPD Full Year Sales Forecast - L4WK": number;
  "NPD Full Year Sales Forecast - L13WK": number;
  "NPD Sales VS TM Status - LW": number;
  "NPD Sales VS TM Status - L4WK": number;
  "NPD Sales VS TM Status - L13WK": number;
  "NPD Avg Price - LW": number;
  "NPD Avg Price - L4WK": number;
  "NPD Avg Price - L13WK": number;
  "NPD Avg Price Difference % - LW": number;
  "NPD Avg Price Difference % - L4WK": number;
  "NPD Avg Price Difference % - L13WK": number;
  "NPD Avg Price Status - LW": string;
  "NPD Avg Price Status - L4WK": string;
  "NPD Avg Price Status - L13WK": string;
  "NPD Sales $ Rank - LW": number;
  "NPD Sales $ Rank - L4WK": number;
  "NPD Sales $ Rank - L13WK": number;
  "Aged Stock Clear by Xmas": string;
  "Aged Stock at Original Price $": number;
  "Aged Stock Sales $ LW": number;
  "Aged Stock sell thru %": number;
}

interface ReviewObj {
  Image: string;
  Link: string;
  "Item Code": string;
  Description: string;
  "Supplier Buying Manager": string;
  Buyer: string;
  Department: string;
  Category: string;
  SubCategory: string;
  Segment: string;
  ItemSource: string;
  RangeStatus: string;
  ItemType: string;
  "Item Planning": string;
  "Supplier Group": string;
  "Supplier Account": string;
  "Showroom or Brand Owner": string;
  "Vendor Item Number": string;
  "Previous Foreign Cost": number;
  "Current Foreign Cost": number;
  "Supplier List Cost": number;
  "Last PO Cost": number;
  "Average Cost": number;
  Price: number;
  "GP%": number;
  "Closeout Price": number;
  MSRP: number;
  "Price Point": string;
  "Effective Carton CBM": number;
  "Outer Ctn Qty": number;
  "Inner Ctn Qty": number;
  "Effective Item CBM": number;
  "CBM Type": string;
  "Effective Item Size": string;
  "Number of Stores In Grade": number;
  Grade: number;
  "Days Since First Sold": number;
  "Commit Pcs": number;
  "Total SOH Stores": number;
  "Total SOH Stores + DC": number;
  "Total SOO Stores + DC": number;
  "Supplier Hold QTY": number;
  "Best WK Sales 52W": number;
  "Best WK Sales 13W": number;
  "52WK Forecast": number;
  "13 WK Forecast": number;
  "Trading Forecast": number;
  "Range Review Forecast": number;
  "Range Review Forecast at Retail $": number;
  "Current H2H Forecast Units": number;
  "Number of Stores OOS": number;
  "MAX L4W Instock%": number;
  "LW Instock%": number;
  "LW Qty Sold": number;
  "LW-1 Qty Sold": number;
  "LW-2 Qty Sold": number;
  "LW-3 Qty Sold": number;
  "LW-4 Qty Sold": number;
  "YTD Qty Sold": number;
  "Rolling Year Qty Sold": number;
  "LY Qty Sold": number;
  "LY H2H Qty Sold": number;
  "Order Pcs": string;
  "Shipment Container Type": string;
  "Revised Landed": string;
  "Revised Sell": string;
  "Total Cost": string;
  "Total Sell": string;
  "Total CBM": string;
  "Position Last Week": number;
  "Lead Competitor Name": string;
  "Lead Competitor Price": number;
}

function Popup({ inputJSON, show, setShow }: PopupProps) {
  const handleExit = () => {};
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    getSummary();
  };

  const handleClick_Close = () => {
    handleClose();
  };

  const [IsImageIncluded, setIsImageIncluded] = useState<boolean>(true);
  const [SummaryList, setSummaryList] = useState<SummaryMainObj[]>([]);
  const [SummaryCatCloseout, setSummaryCatCloseout] = useState<SummaryCatObj[]>([]);
  const [SummaryCatQuit, setSummaryCatQuit] = useState<SummaryCatObj[]>([]);
  const [SummaryCatFOB, setSummaryCatFOB] = useState<SummaryCatObj[]>([]);
  const [SummaryCatLocal, setSummaryCatLocal] = useState<SummaryCatObj[]>([]);

  const [Message, setMessage] = useState<string>("");

  const getSupplierQuote = async () => {
    let supplierQuoteToReturn: SupplierQuoteObj[] = [];
    const res = await runFetch(
      spURL_POST + "Portal_Summary_SupplierQuote_Get",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        supplierQuoteToReturn = spResponse.Table.map((item: SupplierQuoteObj) => ({
          Image: item.Image,
          Link: item.Link,
          Description: item.Description,
          "Item Lookup Code": item["Item Lookup Code"],
          "Reorder No": item["Reorder No"],
          "USD Cost": item["USD Cost"],
          "Last PO Cost": item["Last PO Cost"],
          SOH: item.SOH,
          OO: item.OO,
          MPQ: item.MPQ,
          Buyer: item.Buyer,
          Dept: item.Dept,
          Cat: item.Cat,
        }));
      }
    }

    return supplierQuoteToReturn;
  };

  const getTopItems = async () => {
    let topItemsToReturn: TopItemsObj[] = [];
    const res = await runFetch(
      spURL_POST + "Portal_Summary_TopItems_Get",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        topItemsToReturn = spResponse.Table.map((item: TopItemsObj) => ({
          Image: item.Image,
          Link: item.Link,
          ItemNo: item.ItemNo,
          Description: item.Description,
          Category: item.Category,
          ItemType: item.ItemType,
          "Range Status": item["Range Status"],
          Supplier: item.Supplier,
          "Reorder No": item["Reorder No"],
          Price: item.Price,
          "Avg Sell": item["Avg Sell"],
          "Qty Sold Last 7 Days": item["Qty Sold Last 7 Days"],
          "Qty Sold Yesterday": item["Qty Sold Yesterday"],
          Sales: item.Sales,
          Margin: item.Margin,
          "Margin%": item["Margin%"],
          SOH: item.SOH,
          SOO: item.SOO,
          Buyer: item.Buyer,
          MPQ: item.MPQ,
          "LW Instock%": item["LW Instock%"],
        }));
      }
    }

    return topItemsToReturn;
  };

  const getStockStatus = async () => {
    let stockStatusToReturn: StockStatusObj[] = [];
    const res = await runFetch(
      spURL_POST + "Portal_Summary_StockStatus_Get",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        stockStatusToReturn = spResponse.Table.map((item: StockStatusObj) => ({
          Image: item["Image"],
          Link: item.Link,
          "Item Code": item["Item Code"],
          Description: item["Description"],
          "Supplier Buying Manager": item["Supplier Buying Manager"],
          Buyer: item["Buyer"],
          Department: item["Department"],
          Category: item["Category"],
          SubCategory: item["SubCategory"],
          Segment: item["Segment"],
          ItemSource: item["ItemSource"],
          RangeStatus: item["RangeStatus"],
          ItemType: item["ItemType"],
          "Item Planning": item["Item Planning"],
          "Season Code": item["Season Code"],
          QuitCycle: item["QuitCycle"],
          "Previous Catalogue": item["Previous Catalogue"],
          "Current Catalogue": item["Current Catalogue"],
          "Next Catalogue": item["Next Catalogue"],
          "In Season": item["In Season"],
          "Hot Xmas Line": item["Hot Xmas Line"],
          "Target Line Instock Instore at Xmas": item["Target Line Instock Instore at Xmas"],
          "Evergreen Status": item["Evergreen Status"],
          "Web Assortment": item["Web Assortment"],
          "Number of Barcodes Loaded": item["Number of Barcodes Loaded"],
          "Number of Barcodes Sold": item["Number of Barcodes Sold"],
          "Battery Type": item["Battery Type"],
          "Age Grading": item["Age Grading"],
          "Picking Group": item["Picking Group"],
          "Supplier Group": item["Supplier Group"],
          "Supplier Account": item["Supplier Account"],
          "Showroom or Brand Owner": item["Showroom or Brand Owner"],
          Licensor: item["Licensor"],
          "Vendor Item Number": item["Vendor Item Number"],
          "Previous Foreign Cost": item["Previous Foreign Cost"],
          "Current Foreign Cost": item["Current Foreign Cost"],
          "Ship Region": item["Ship Region"],
          Port: item["Port"],
          "Calculated Landed Cost": item["Calculated Landed Cost"],
          "Supplier List Cost": item["Supplier List Cost"],
          "Last PO Cost": item["Last PO Cost"],
          "Average Cost": item["Average Cost"],
          Price: item["Price"],
          "GP%": item["GP%"],
          "Closeout Price": item["Closeout Price"],
          "Avg Sell Price LW": item["Avg Sell Price LW"],
          "Avg Sell Price L4W": item["Avg Sell Price L4W"],
          "Avg Sell Price L13W": item["Avg Sell Price L13W"],
          "Avg Price LW Online": item["Avg Price LW Online"],
          "Buisness Promo Price": item["Buisness Promo Price"],
          MSRP: item["MSRP"],
          "Lowest Store Promo Price": item["Lowest Store Promo Price"],
          "Price Point": item["Price Point"],
          "Available Date": item["Available Date"],
          "Is Item on Preorder": item["Is Item on Preorder"],
          "Days Until Earliest Available": item["Days Until Earliest Available"],
          "Suitable for Preorder": item["Suitable for Preorder"],
          "Effective Carton CBM": item["Effective Carton CBM"],
          "Outer Ctn Qty": item["Outer Ctn Qty"],
          "Inner Ctn Qty": item["Inner Ctn Qty"],
          "Effective Item CBM": item["Effective Item CBM"],
          "CBM Type": item["CBM Type"],
          "Effective Item Size": item["Effective Item Size"],
          "Box Size Type": item["Box Size Type"],
          MOQ: item["MOQ"],
          "Number of Stores In Grade": item["Number of Stores In Grade"],
          Grade: item["Grade"],
          "Days Since First Sold": item["Days Since First Sold"],
          Age: item["Age"],
          "SOH Above $3000 in Store and No Sales LW":
            item["SOH Above $3000 in Store and No Sales LW"],
          "Item Over 1 Year Old - No Stock Received":
            item["Item Over 1 Year Old - No Stock Received"],
          "Trading FC Stockturn Below 1": item["Trading FC Stockturn Below 1"],
          "Top 20 Line Commitment $ + Trading FC under 0.8":
            item["Top 20 Line Commitment $ + Trading FC under 0.8"],
          "Margin Outlier": item["Margin Outlier"],
          "Under 25% Margin Last Week": item["Under 25% Margin Last Week"],
          "NPD Underperformer": item["NPD Underperformer"],
          "Top 250 SKUs in Retail Sales Inc": item["Top 250 SKUs in Retail Sales Inc"],
          "Top 250 Feature in L13W": item["Top 250 Feature in L13W"],
          "Top 600 Feature in L13W": item["Top 600 Feature in L13W"],
          "How Many Top 250 Feature in L13": item["How Many Top 250 Feature in L13"],
          "Top 250 Improved Sellers": item["Top 250 Improved Sellers"],
          "Top250L13W Under Instock Threshold (75%)":
            item["Top250L13W Under Instock Threshold (75%)"],
          "Action Taken to Improve Instock": item["Action Taken to Improve Instock"],
          "Top 20 Commitment by Buyer": item["Top 20 Commitment by Buyer"],
          "Markup Opportunity": item["Markup Opportunity"],
          "Markdown Opportunity": item["Markdown Opportunity"],
          "Needs Reorder": item["Needs Reorder"],
          "Price Above 15% Lead": item["Price Above 15% Lead"],
          "Price Above 30% Lead": item["Price Above 30% Lead"],
          "Suggested New Price": item["Suggested New Price"],
          "Calculated Markdown Value at New Price": item["Calculated Markdown Value at New Price"],
          "Season Big Bets": item["Season Big Bets"],
          "Supplier Market Quota": item["Supplier Market Quota"],
          "Supplier Toymate Buy Plan": item["Supplier Toymate Buy Plan"],
          "Supplier Buy Plan RRP": item["Supplier Buy Plan RRP"],
          "Toymate Buy Plan": item["Toymate Buy Plan"],
          "July/Aug Bulk Qty": item["July/Aug Bulk Qty"],
          "Xmas Bulk Qty": item["Xmas Bulk Qty"],
          "Retail $ LW": item["Retail $ LW"],
          "LW GP $": item["LW GP $"],
          "LW GP %": item["LW GP %"],
          "Qty Sold LW": item["Qty Sold LW"],
          "L4W Qty Sold": item["L4W Qty Sold"],
          "L13W Qty Sold": item["L13W Qty Sold"],
          "Commit Pcs": item["Commit Pcs"],
          "Total SOH Stores": item["Total SOH Stores"],
          "Total SOH Stores + DC": item["Total SOH Stores + DC"],
          "Total SOO Stores + DC": item["Total SOO Stores + DC"],
          "Total SOO Pcs Due": item["Total SOO Pcs Due"],
          "Total SOO 0-30": item["Total SOO 0-30"],
          "Total SOO 30-60": item["Total SOO 30-60"],
          "Total SOO 60-90": item["Total SOO 60-90"],
          "Total SOO 90+": item["Total SOO 90+"],
          "Total PO Stores": item["Total PO Stores"],
          "Total PO Qty Warehouse": item["Total PO Qty Warehouse"],
          "Supplier Hold QTY": item["Supplier Hold QTY"],
          "SOH Warehouse Available": item["SOH Warehouse Available"],
          "SOH Warehouse On Transfer": item["SOH Warehouse On Transfer"],
          "SOH Warehouse": item["SOH Warehouse"],
          "Intertrading Pallets": item["Intertrading Pallets"],
          "Total Available Intertrading": item["Total Available Intertrading"],
          "Total Intertrading SSCC": item["Total Intertrading SSCC"],
          "Total SOH Intertrading": item["Total SOH Intertrading"],
          "WH Difference Pcs": item["WH Difference Pcs"],
          "WH Difference Cost": item["WH Difference Cost"],
          "Total SOH Stores Cost$ Ex GST": item["Total SOH Stores Cost$ Ex GST"],
          "Total SOH Stores Retail$ Inc GST": item["Total SOH Stores Retail$ Inc GST"],
          "Total SOO Cost$": item["Total SOO Cost$"],
          "Total SOO Retail$": item["Total SOO Retail$"],
          "Total Committed Cost$": item["Total Committed Cost$"],
          "Total Committed Retail$": item["Total Committed Retail$"],
          "Total Forward Weeks Cover SOH": item["Total Forward Weeks Cover SOH"],
          "Total Forward Weeks Cover Committed": item["Total Forward Weeks Cover Committed"],
          "Best WK Rolling 2 Year": item["Best WK Rolling 2 Year"],
          "2nd Best WK Rolling 2 Year": item["2nd Best WK Rolling 2 Year"],
          "Best WK Rolling 1 Year": item["Best WK Rolling 1 Year"],
          "2nd Best WK Rolling 1 Year": item["2nd Best WK Rolling 1 Year"],
          "Best WK Sales 52W": item["Best WK Sales 52W"],
          "Best WK Sales 13W": item["Best WK Sales 13W"],
          "Best WK Sales 8W": item["Best WK Sales 8W"],
          TradingStatus: item["TradingStatus"],
          "52WK Forecast": item["52WK Forecast"],
          "13 WK Forecast": item["13 WK Forecast"],
          "Saved Forecast": item["Saved Forecast"],
          "Trading Forecast": item["Trading Forecast"],
          "Trading Forecast$": item["Trading Forecast$"],
          "Counted on RR Summary": item["Counted on RR Summary"],
          "Line Order of RR": item["Line Order of RR"],
          "Range Review Forecast": item["Range Review Forecast"],
          "Range Review Forecast at Retail $": item["Range Review Forecast at Retail $"],
          UPSPW: item["UPSPW"],
          "Current H2H Forecast Units": item["Current H2H Forecast Units"],
          "Current H2H Forecast Retail $": item["Current H2H Forecast Retail $"],
          "Historical Forecast": item["Historical Forecast"],
          "Number of Stores OOS": item["Number of Stores OOS"],
          "MAX L4W Instock%": item["MAX L4W Instock%"],
          "LW Instock%": item["LW Instock%"],
          "LW Instock Committed%": item["LW Instock Committed%"],
          "LW Qty Sold": item["LW Qty Sold"],
          "LW-1 Qty Sold": item["LW-1 Qty Sold"],
          "LW-2 Qty Sold": item["LW-2 Qty Sold"],
          "LW-3 Qty Sold": item["LW-3 Qty Sold"],
          "LW-4 Qty Sold": item["LW-4 Qty Sold"],
          "YTD Qty Sold": item["YTD Qty Sold"],
          "Rolling Year Qty Sold": item["Rolling Year Qty Sold"],
          "LY Qty Sold": item["LY Qty Sold"],
          "LY H2H Qty Sold": item["LY H2H Qty Sold"],
          "LW Retail $": item["LW Retail $"],
          "YTD Retail $": item["YTD Retail $"],
          "Rolling Year Retail $": item["Rolling Year Retail $"],
          "LY Retail $": item["LY Retail $"],
          "LY H2H Retail $": item["LY H2H Retail $"],
          "Is Item Listed": item["Is Item Listed"],
          "Image Loaded": item["Image Loaded"],
          "Auburn SOH": item["Auburn SOH"],
          "Auburn Committed": item["Auburn Committed"],
          "Web Buffer": item["Web Buffer"],
          "Online SOH": item["Online SOH"],
          "Online Forecast 52 Weeks": item["Online Forecast 52 Weeks"],
          "Online Forecast 13 Weeks": item["Online Forecast 13 Weeks"],
          "Online Forecast": item["Online Forecast"],
          "Trading Forecast Online": item["Trading Forecast Online"],
          "LW Qty Sold Online": item["LW Qty Sold Online"],
          "LW Retail Online $": item["LW Retail Online $"],
          "Online % of Sales": item["Online % of Sales"],
          "Site Visits LW": item["Site Visits LW"],
          "LW Cost of Marketing as % of Sales Online":
            item["LW Cost of Marketing as % of Sales Online"],
          "LW Cost of Marketing as % of Sales Buisness":
            item["LW Cost of Marketing as % of Sales Buisness"],
          "L4W Retail Online $": item["L4W Retail Online $"],
          "L4W Qty Sold Online": item["L4W Qty Sold Online"],
          "Online % of Sales L4W": item["Online % of Sales L4W"],
          "Site Vistis L4W": item["Site Vistis L4W"],
          "Google visits L4W": item["Google visits L4W"],
          "L4W Cost of Marketing as % of Sales Online":
            item["L4W Cost of Marketing as % of Sales Online"],
          "L4W Cost of Marketing as % of Sales Buisness":
            item["L4W Cost of Marketing as % of Sales Buisness"],
          "Web Traffic Ranking L4W": item["Web Traffic Ranking L4W"],
          "Position Last Week": item["Position Last Week"],
          "Curent Positon": item["Curent Positon"],
          "Lead Competitor Name": item["Lead Competitor Name"],
          "Lead Competitor Price": item["Lead Competitor Price"],
          "Competitive Detail TW": item["Competitive Detail TW"],
          "% Diff Lead Price TW": item["% Diff Lead Price TW"],
          "# Price Matches LW": item["# Price Matches LW"],
          "Avg Price for Price Matches LW": item["Avg Price for Price Matches LW"],
          "Trading Forecast Stockturn SOH": item["Trading Forecast Stockturn SOH"],
          "Trading Forecast Stockturn Committed SOH":
            item["Trading Forecast Stockturn Committed SOH"],
          "Price History (Ex Promo)": item["Price History (Ex Promo)"],
          "Last Price Change Reason": item["Last Price Change Reason"],
          "Days Since Last Price Change": item["Days Since Last Price Change"],
          "Markdown Forecast Since Last Price Change":
            item["Markdown Forecast Since Last Price Change"],
          "Latest Price Change Stockturn SOH": item["Latest Price Change Stockturn SOH"],
          "Next 8 Weeks Sell Through": item["Next 8 Weeks Sell Through"],
          "Season Sell Through": item["Season Sell Through"],
          "Sellout Month": item["Sellout Month"],
          "% Sales Since Last Price Change": item["% Sales Since Last Price Change"],
          "% Margin Since Last Price Change": item["% Margin Since Last Price Change"],
          "Last Promo Price": item["Last Promo Price"],
          "Promo Sales Uplift %": item["Promo Sales Uplift %"],
          "Promo GP Uplift %": item["Promo GP Uplift %"],
          "Past Season Markdown Flag": item["Past Season Markdown Flag"],
          "Current Season Markdown Flag": item["Current Season Markdown Flag"],
          "Supplier Line Performance Indicator": item["Supplier Line Performance Indicator"],
          "Vendor Name": item["Vendor Name"],
          "Smyths Forecast": item["Smyths Forecast"],
          "52WK max retail": item["52WK max retail"],
          "Smyths USD": item["Smyths USD"],
          "Retail Pounds": item["Retail Pounds"],
          "YTD Faulty Returns": item["YTD Faulty Returns"],
          "LY Faulty Returns": item["LY Faulty Returns"],
          "Rolling Year Faulty Returns (L52W)": item["Rolling Year Faulty Returns (L52W)"],
          "Prior Rolling Year Faulty Returns (53W-104W)":
            item["Prior Rolling Year Faulty Returns (53W-104W)"],
          "Out of Box": item["Out of Box"],
          "Merch Spot": item["Merch Spot"],
          "Lead Sale Status": item["Lead Sale Status"],
          OHS: item["OHS"],
          "Item Category Code": item["Item Category Code"],
          "Cat Min Margin %": item["Cat Min Margin %"],
          "Cat Max Margin %": item["Cat Max Margin %"],
          "Cost or Margin Issue": item["Cost or Margin Issue"],
          "CBM Issue": item["CBM Issue"],
          "Item Size": item["Item Size"],
          "Item Size as Per Priced Based CBM": item["Item Size as Per Priced Based CBM"],
          "% 1 Facing Takes up of Bay": item["% 1 Facing Takes up of Bay"],
          "Instock Stock required (15WOC)": item["Instock Stock required (15WOC)"],
          "Instock Stock Required CBM": item["Instock Stock Required CBM"],
          "Maximum Between Ctn and Instock CBM": item["Maximum Between Ctn and Instock CBM"],
          "Maximum as a Bay Number": item["Maximum as a Bay Number"],
          "Facings Sales Multiplier": item["Facings Sales Multiplier"],
          "No of Facings Taken": item["No of Facings Taken"],
          "Max Units on Shelf": item["Max Units on Shelf"],
          "Item Size Sales Criteria": item["Item Size Sales Criteria"],
          "Meets Criteria": item["Meets Criteria"],
          "Total Space on Bays Taken": item["Total Space on Bays Taken"],
          "Sales per Bay inc GST": item["Sales per Bay inc GST"],
          "Margin $ Per Bay": item["Margin $ Per Bay"],
          "Stock Holding WOC Check": item["Stock Holding WOC Check"],
          "Stock Holding WOC Issue (Above 30)": item["Stock Holding WOC Issue (Above 30)"],
          "NPD Reorder No Matches": item["NPD Reorder No Matches"],
          "NPD Matched LineID": item["NPD Matched LineID"],
          "NPD Manufacturer": item["NPD Manufacturer"],
          "NPD Supercategory": item["NPD Supercategory"],
          "NPD Property": item["NPD Property"],
          "NPD UPSPW - LW": item["NPD UPSPW - LW"],
          "NPD UPSPW - L4WK": item["NPD UPSPW - L4WK"],
          "NPD UPSPW - L13WK": item["NPD UPSPW - L13WK"],
          "NPD Full Year Sales Forecast - LW": item["NPD Full Year Sales Forecast - LW"],
          "NPD Full Year Sales Forecast - L4WK": item["NPD Full Year Sales Forecast - L4WK"],
          "NPD Full Year Sales Forecast - L13WK": item["NPD Full Year Sales Forecast - L13WK"],
          "NPD Sales VS TM Status - LW": item["NPD Sales VS TM Status - LW"],
          "NPD Sales VS TM Status - L4WK": item["NPD Sales VS TM Status - L4WK"],
          "NPD Sales VS TM Status - L13WK": item["NPD Sales VS TM Status - L13WK"],
          "NPD Avg Price - LW": item["NPD Avg Price - LW"],
          "NPD Avg Price - L4WK": item["NPD Avg Price - L4WK"],
          "NPD Avg Price - L13WK": item["NPD Avg Price - L13WK"],
          "NPD Avg Price Difference % - LW": item["NPD Avg Price Difference % - LW"],
          "NPD Avg Price Difference % - L4WK": item["NPD Avg Price Difference % - L4WK"],
          "NPD Avg Price Difference % - L13WK": item["NPD Avg Price Difference % - L13WK"],
          "NPD Avg Price Status - LW": item["NPD Avg Price Status - LW"],
          "NPD Avg Price Status - L4WK": item["NPD Avg Price Status - L4WK"],
          "NPD Avg Price Status - L13WK": item["NPD Avg Price Status - L13WK"],
          "NPD Sales $ Rank - LW": item["NPD Sales $ Rank - LW"],
          "NPD Sales $ Rank - L4WK": item["NPD Sales $ Rank - L4WK"],
          "NPD Sales $ Rank - L13WK": item["NPD Sales $ Rank - L13WK"],
          "Aged Stock Clear by Xmas": item["Aged Stock Clear by Xmas"],
          "Aged Stock at Original Price $": item["Aged Stock at Original Price $"],
          "Aged Stock Sales $ LW": item["Aged Stock Sales $ LW"],
          "Aged Stock sell thru %": item["Aged Stock sell thru %"],
        }));
      }
    }

    return stockStatusToReturn;
  };

  const getReview = async () => {
    let reviewToReturn: ReviewObj[] = [];
    const res = await runFetch(spURL_POST + "Portal_Summary_Review_Get", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        reviewToReturn = spResponse.Table.map((item: ReviewObj) => ({
          Image: item["Image"],
          Link: item.Link,
          "Item Code": item["Item Code"],
          Description: item["Description"],
          "Supplier Buying Manager": item["Supplier Buying Manager"],
          Buyer: item["Buyer"],
          Department: item["Department"],
          Category: item["Category"],
          SubCategory: item["SubCategory"],
          Segment: item["Segment"],
          ItemSource: item["ItemSource"],
          RangeStatus: item["RangeStatus"],
          ItemType: item["ItemType"],
          "Item Planning": item["Item Planning"],
          "Supplier Group": item["Supplier Group"],
          "Supplier Account": item["Supplier Account"],
          "Showroom or Brand Owner": item["Showroom or Brand Owner"],
          "Vendor Item Number": item["Vendor Item Number"],
          "Previous Foreign Cost": item["Previous Foreign Cost"],
          "Current Foreign Cost": item["Current Foreign Cost"],
          "Supplier List Cost": item["Supplier List Cost"],
          "Last PO Cost": item["Last PO Cost"],
          "Average Cost": item["Average Cost"],
          Price: item["Price"],
          "GP%": item["GP%"],
          "Closeout Price": item["Closeout Price"],
          MSRP: item["MSRP"],
          "Price Point": item["Price Point"],
          "Effective Carton CBM": item["Effective Carton CBM"],
          "Outer Ctn Qty": item["Outer Ctn Qty"],
          "Inner Ctn Qty": item["Inner Ctn Qty"],
          "Effective Item CBM": item["Effective Item CBM"],
          "CBM Type": item["CBM Type"],
          "Effective Item Size": item["Effective Item Size"],
          "Number of Stores In Grade": item["Number of Stores In Grade"],
          Grade: item["Grade"],
          "Days Since First Sold": item["Days Since First Sold"],
          "Commit Pcs": item["Commit Pcs"],
          "Total SOH Stores": item["Total SOH Stores"],
          "Total SOH Stores + DC": item["Total SOH Stores + DC"],
          "Total SOO Stores + DC": item["Total SOO Stores + DC"],
          "Supplier Hold QTY": item["Supplier Hold QTY"],
          "Best WK Sales 52W": item["Best WK Sales 52W"],
          "Best WK Sales 13W": item["Best WK Sales 13W"],
          "52WK Forecast": item["52WK Forecast"],
          "13 WK Forecast": item["13 WK Forecast"],
          "Trading Forecast": item["Trading Forecast"],
          "Range Review Forecast": item["Range Review Forecast"],
          "Range Review Forecast at Retail $": item["Range Review Forecast at Retail $"],
          "Current H2H Forecast Units": item["Current H2H Forecast Units"],
          "Number of Stores OOS": item["Number of Stores OOS"],
          "MAX L4W Instock%": item["MAX L4W Instock%"],
          "LW Instock%": item["LW Instock%"],
          "LW Qty Sold": item["LW Qty Sold"],
          "LW-1 Qty Sold": item["LW-1 Qty Sold"],
          "LW-2 Qty Sold": item["LW-2 Qty Sold"],
          "LW-3 Qty Sold": item["LW-3 Qty Sold"],
          "LW-4 Qty Sold": item["LW-4 Qty Sold"],
          "YTD Qty Sold": item["YTD Qty Sold"],
          "Rolling Year Qty Sold": item["Rolling Year Qty Sold"],
          "LY Qty Sold": item["LY Qty Sold"],
          "LY H2H Qty Sold": item["LY H2H Qty Sold"],
          "Order Pcs": item["Order Pcs"],
          "Shipment Container Type": item["Shipment Container Type"],
          "Revised Landed": item["Revised Landed"],
          "Revised Sell": item["Revised Sell"],
          "Total Cost": item["Total Cost"],
          "Total Sell": item["Total Sell"],
          "Total CBM": item["Total CBM"],
          "Position Last Week": item["Position Last Week"],
          "Lead Competitor Name": item["Lead Competitor Name"],
          "Lead Competitor Price": item["Lead Competitor Price"],
        }));
      }
    }

    return reviewToReturn;
  };

  const getSummary = async () => {
    setSummaryList([]);
    setSummaryCatCloseout([]);
    setSummaryCatQuit([]);
    setSummaryCatFOB([]);
    setSummaryCatLocal([]);

    const res = await runFetch(spURL_POST + "Portal_Summary_Get", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setSummaryList(
          spResponse.Table.map((item: SummaryMainObj) => ({
            CF: item.CF,
            QUIT: item.QUIT,
            "Price Point (0-10)": item["Price Point (0-10)"],
            "Price Point (10-20)": item["Price Point (10-20)"],
            "Price Point (20-30)": item["Price Point (20-30)"],
            "Price Point (30-50)": item["Price Point (30-50)"],
            "Price Point (50-100)": item["Price Point (50-100)"],
            "Price Point (100+)": item["Price Point (100+)"],
            "Margin Band (Negative)": item["Margin Band (Negative)"],
            "Margin Band (0-25)": item["Margin Band (0-25)"],
            "Margin Band (25-40)": item["Margin Band (25-40)"],
            "Margin Band (40-55)": item["Margin Band (40-55)"],
            "Margin Band (55+)": item["Margin Band (55+)"],
            "Competitor (No Competitor)": item["Competitor (No Competitor)"],
            "Competitor (20% + Cheaper)": item["Competitor (20% + Cheaper)"],
            "Competitor (0-20 % Cheaper)": item["Competitor (0-20 % Cheaper)"],
            "Competitor (0-20 % More)": item["Competitor (0-20 % More)"],
            "Competitor (20-40 % More)": item["Competitor (20-40 % More)"],
            "Competitor (40% + More)": item["Competitor (40% + More)"],
            "Private Label": item["Private Label"],
            "Not Private Label": item["Not Private Label"],
            "SOH Cover (0-4)": item["SOH Cover (0-4)"],
            "SOH Cover (4-8)": item["SOH Cover (4-8)"],
            "SOH Cover (Normal)": item["SOH Cover (Normal)"],
            "SOH Cover (52+)": item["SOH Cover (52+)"],
            "L4W Instock (Never Stocked)": item["L4W Instock (Never Stocked)"],
            "L4W Instock (0-25%)": item["L4W Instock (0-25%)"],
            "L4W Instock (25-50%)": item["L4W Instock (25-50%)"],
            "L4W Instock (50-75%)": item["L4W Instock (50-75%)"],
            "L4W Instock (75% +)": item["L4W Instock (75% +)"],
            "RR Forecast Retail": item["RR Forecast Retail"],
            "Trading Forecast Retail": item["Trading Forecast Retail"],
            "Rolling Year Sales": item["Rolling Year Sales"],
            "H2H LY Sales": item["H2H LY Sales"],
            "LW Sales": item["LW Sales"],
            "L4W Sales": item["L4W Sales"],
            "SOH Retail": item["SOH Retail"],
            "SOO Retail": item["SOO Retail"],
            "Committed Retail": item["Committed Retail"],
            "Commit Stockturn of RR Forecast Retail":
              item["Commit Stockturn of RR Forecast Retail"],
            "Commit Stockturn on Rolling Year Sales":
              item["Commit Stockturn on Rolling Year Sales"],
            "Commit Coverage of H2H Sales Plus MD(10%)":
              item["Commit Coverage of H2H Sales Plus MD(10%)"],
          }))
        );
      }
      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setSummaryCatCloseout(
          spResponse.Table1.map((item: SummaryCatObj) => ({
            Category: item.Category,
            "Review Forecast$": item["Review Forecast$"],
            "Trading Forecast$": item["Trading Forecast$"],
            "H2H Review Forecast$": item["H2H Review Forecast$"],
            "H2H Trading Forecast$": item["H2H Trading Forecast$"],
            SOH$: item.SOH$,
            SOO$: item.SOO$,
            Commit$: item.Commit$,
          }))
        );
      }

      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setSummaryCatQuit(
          spResponse.Table2.map((item: SummaryCatObj) => ({
            Category: item.Category,
            "Review Forecast$": item["Review Forecast$"],
            "Trading Forecast$": item["Trading Forecast$"],
            "H2H Review Forecast$": item["H2H Review Forecast$"],
            "H2H Trading Forecast$": item["H2H Trading Forecast$"],
            SOH$: item.SOH$,
            SOO$: item.SOO$,
            Commit$: item.Commit$,
          }))
        );
      }
      if (spResponse.Table3 !== undefined && spResponse.Table3.length > 0) {
        setSummaryCatFOB(
          spResponse.Table3.map((item: SummaryCatObj) => ({
            Category: item.Category,
            "Review Forecast$": item["Review Forecast$"],
            "Trading Forecast$": item["Trading Forecast$"],
            "H2H Review Forecast$": item["H2H Review Forecast$"],
            "H2H Trading Forecast$": item["H2H Trading Forecast$"],
            SOH$: item.SOH$,
            SOO$: item.SOO$,
            Commit$: item.Commit$,
          }))
        );
      }
      if (spResponse.Table4 !== undefined && spResponse.Table4.length > 0) {
        setSummaryCatLocal(
          spResponse.Table4.map((item: SummaryCatObj) => ({
            Category: item.Category,
            "Review Forecast$": item["Review Forecast$"],
            "Trading Forecast$": item["Trading Forecast$"],
            "H2H Review Forecast$": item["H2H Review Forecast$"],
            "H2H Trading Forecast$": item["H2H Trading Forecast$"],
            SOH$: item.SOH$,
            SOO$: item.SOO$,
            Commit$: item.Commit$,
          }))
        );
      }
    }
  };

  const renderSummaryMainHeader = () => {
    return (
      <thead>
        <tr>
          <th>CF</th>
          <th>QUIT</th>
          <th>Price ($0-$10)</th>
          <th>Price ($10-$20)</th>
          <th>Price ($20-$30)</th>
          <th>Price ($30-$50)</th>
          <th>Price ($50-$100)</th>
          <th>Price ($100+)</th>
          <th>GP (&lt;0%)</th>
          <th>GP (0%-25%)</th>
          <th>GP (25%-40%)</th>
          <th>GP (40%-55%)</th>
          <th>GP (55%+)</th>
          <th>Comp (None)</th>
          <th>Comp (20%+ Less)</th>
          <th>Comp (0%-20% Less)</th>
          <th>Comp (0%-20% More)</th>
          <th>Comp (20%-40% More)</th>
          <th>Comp (40%+ More)</th>
          <th>Private Label</th>
          <th>Not Private Label</th>
          <th>SOH WOC (0-4)</th>
          <th>SOH WOC (4-8)</th>
          <th>SOH WOC (Normal)</th>
          <th>SOH WOC (52+)</th>
          <th>L4W Instock (Never)</th>
          <th>L4W Instock (&lt;25%)</th>
          <th>L4W Instock (25%-50%)</th>
          <th>L4W Instock (50%-75%)</th>
          <th>L4W Instock (75%+)</th>
          <th>RR Forecast Retail</th>
          <th>Trading Forecast Retail</th>
          <th>Rolling Y Sales</th>
          <th>H2H LY Sales</th>
          <th>LW Sales</th>
          <th>L4W Sales</th>
          <th>SOH Retail</th>
          <th>SOO Retail</th>
          <th>Committed Retail</th>
          <th>RR Stockturn</th>
          <th>Rolling Y Stockturn</th>
          <th>H2H+MD Stockturn</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryGeneralHeader = () => {
    return (
      <thead>
        <tr>
          <th>CF</th>
          <th>QUIT</th>
          <th>Private Label</th>
          <th>Not Private Label</th>
          <th>LW Sales</th>
          <th>L4W Sales</th>
          <th>Rolling Y Sales</th>
          <th>H2H LY Sales</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryPriceHeader = () => {
    return (
      <thead>
        <tr>
          <th>Price ($0-$10)</th>
          <th>Price ($10-$20)</th>
          <th>Price ($20-$30)</th>
          <th>Price ($30-$50)</th>
          <th>Price ($50-$100)</th>
          <th>Price ($100+)</th>
          <th>GP (&lt;0%)</th>
          <th>GP (0%-25%)</th>
          <th>GP (25%-40%)</th>
          <th>GP (40%-55%)</th>
          <th>GP (55%+)</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryCompHeader = () => {
    return (
      <thead>
        <tr>
          <th>Comp (None)</th>
          <th>Comp (20%+ Less)</th>
          <th>Comp (0%-20% Less)</th>
          <th>Comp (0%-20% More)</th>
          <th>Comp (20%-40% More)</th>
          <th>Comp (40%+ More)</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryInvHeader = () => {
    return (
      <thead>
        <tr>
          <th>SOH Retail</th>
          <th>SOO Retail</th>
          <th>Committed Retail</th>
          <th>L4W Instock (Never)</th>
          <th>L4W Instock (&lt;25%)</th>
          <th>L4W Instock (25%-50%)</th>
          <th>L4W Instock (50%-75%)</th>
          <th>L4W Instock (75%+)</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryCatHeader = () => {
    return (
      <thead>
        <tr>
          <th>Category</th>
          <th>Review Forecast$</th>
          <th>Trading Forecast$</th>
          <th>H2H Review Forecast$</th>
          <th>H2H Trading Forecast$</th>
          <th>SOH$</th>
          <th>SOO$</th>
          <th>Commit$</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryForecastHeader = () => {
    return (
      <thead>
        <tr>
          <th>RR Forecast Retail</th>
          <th>Trading Forecast Retail</th>
          <th>SOH WOC (0-4)</th>
          <th>SOH WOC (4-8)</th>
          <th>SOH WOC (Normal)</th>
          <th>SOH WOC (52+)</th>
          <th>RR Stockturn</th>
          <th>Rolling Y Stockturn</th>
          <th>H2H+MD Stockturn</th>
        </tr>
      </thead>
    );
  };

  const renderSummaryMainBody = () => {
    return SummaryList.map((column, index) => (
      <tbody>
        <tr>
          <td>{column.CF}</td>
          <td>{column.QUIT}</td>
          <td>{column["Price Point (0-10)"]}</td>
          <td>{column["Price Point (10-20)"]}</td>
          <td>{column["Price Point (20-30)"]}</td>
          <td>{column["Price Point (30-50)"]}</td>
          <td>{column["Price Point (50-100)"]}</td>
          <td>{column["Price Point (100+)"]}</td>
          <td>{column["Margin Band (Negative)"]}</td>
          <td>{column["Margin Band (0-25)"]}</td>
          <td>{column["Margin Band (25-40)"]}</td>
          <td>{column["Margin Band (40-55)"]}</td>
          <td>{column["Margin Band (55+)"]}</td>
          <td>{column["Competitor (No Competitor)"]}</td>
          <td>{column["Competitor (20% + Cheaper)"]}</td>
          <td>{column["Competitor (0-20 % Cheaper)"]}</td>
          <td>{column["Competitor (0-20 % More)"]}</td>
          <td>{column["Competitor (20-40 % More)"]}</td>
          <td>{column["Competitor (40% + More)"]}</td>
          <td>{column["Private Label"]}</td>
          <td>{column["Not Private Label"]}</td>
          <td>{column["SOH Cover (0-4)"]}</td>
          <td>{column["SOH Cover (4-8)"]}</td>
          <td>{column["SOH Cover (Normal)"]}</td>
          <td>{column["SOH Cover (52+)"]}</td>
          <td>{column["L4W Instock (Never Stocked)"]}</td>
          <td>{column["L4W Instock (0-25%)"]}</td>
          <td>{column["L4W Instock (25-50%)"]}</td>
          <td>{column["L4W Instock (50-75%)"]}</td>
          <td>{column["L4W Instock (75% +)"]}</td>
          <td>{ConvertToCurrency(column["RR Forecast Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["Trading Forecast Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["Rolling Year Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H LY Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["LW Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["L4W Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["SOH Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["SOO Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["Committed Retail"], 0)}</td>
          <td>{column["Commit Stockturn of RR Forecast Retail"]}</td>
          <td>{column["Commit Stockturn on Rolling Year Sales"]}</td>
          <td>{column["Commit Coverage of H2H Sales Plus MD(10%)"]}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryGeneralBody = () => {
    return SummaryList.map((column, index) => (
      <tbody>
        <tr>
          <td>{column.CF}</td>
          <td>{column.QUIT}</td>
          <td>{column["Private Label"]}</td>
          <td>{column["Not Private Label"]}</td>
          <td>{ConvertToCurrency(column["LW Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["L4W Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["Rolling Year Sales"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H LY Sales"], 0)}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryPriceBody = () => {
    return SummaryList.map((column, index) => (
      <tbody>
        <tr>
          <td>{column["Price Point (0-10)"]}</td>
          <td>{column["Price Point (10-20)"]}</td>
          <td>{column["Price Point (20-30)"]}</td>
          <td>{column["Price Point (30-50)"]}</td>
          <td>{column["Price Point (50-100)"]}</td>
          <td>{column["Price Point (100+)"]}</td>
          <td>{column["Margin Band (Negative)"]}</td>
          <td>{column["Margin Band (0-25)"]}</td>
          <td>{column["Margin Band (25-40)"]}</td>
          <td>{column["Margin Band (40-55)"]}</td>
          <td>{column["Margin Band (55+)"]}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryCompBody = () => {
    return SummaryList.map((column, index) => (
      <tbody>
        <tr>
          <td>{column["Competitor (No Competitor)"]}</td>
          <td>{column["Competitor (20% + Cheaper)"]}</td>
          <td>{column["Competitor (0-20 % Cheaper)"]}</td>
          <td>{column["Competitor (0-20 % More)"]}</td>
          <td>{column["Competitor (20-40 % More)"]}</td>
          <td>{column["Competitor (40% + More)"]}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryInvBody = () => {
    return SummaryList.map((column, index) => (
      <tbody>
        <tr>
          <td>{ConvertToCurrency(column["SOH Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["SOO Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["Committed Retail"], 0)}</td>
          <td>{column["L4W Instock (Never Stocked)"]}</td>
          <td>{column["L4W Instock (0-25%)"]}</td>
          <td>{column["L4W Instock (25-50%)"]}</td>
          <td>{column["L4W Instock (50-75%)"]}</td>
          <td>{column["L4W Instock (75% +)"]}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryForecastBody = () => {
    return SummaryList.map((column, index) => (
      <tbody>
        <tr>
          <td>{ConvertToCurrency(column["RR Forecast Retail"], 0)}</td>
          <td>{ConvertToCurrency(column["Trading Forecast Retail"], 0)}</td>
          <td>{column["SOH Cover (0-4)"]}</td>
          <td>{column["SOH Cover (4-8)"]}</td>
          <td>{column["SOH Cover (Normal)"]}</td>
          <td>{column["SOH Cover (52+)"]}</td>
          <td>{column["Commit Stockturn of RR Forecast Retail"]}</td>
          <td>{column["Commit Stockturn on Rolling Year Sales"]}</td>
          <td>{column["Commit Coverage of H2H Sales Plus MD(10%)"]}</td>
        </tr>
      </tbody>
    ));
  };

  const RenderSummaryTable = () => {
    let SummaryTable1 = (
      <>
        <thead>
          <tr>
            <th>General</th>
            <th>CF</th>
            <th>QUIT</th>
            <th>Private Label</th>
            <th>Not Private Label</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{column.CF}</td>
              <td>{column.QUIT}</td>
              <td>{column["Private Label"]}</td>
              <td>{column["Not Private Label"]}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable2 = (
      <>
        <thead>
          <tr>
            <th>Price</th>
            <th>$0-$10</th>
            <th>$10-$20</th>
            <th>$20-$30</th>
            <th>$30-$50</th>
            <th>$50-$100</th>
            <th>$100+</th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{column["Price Point (0-10)"]}</td>
              <td>{column["Price Point (10-20)"]}</td>
              <td>{column["Price Point (20-30)"]}</td>
              <td>{column["Price Point (30-50)"]}</td>
              <td>{column["Price Point (50-100)"]}</td>
              <td>{column["Price Point (100+)"]}</td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable3 = (
      <>
        <thead>
          <tr>
            <th>Sales</th>
            <th>LW</th>
            <th>L4W</th>
            <th>Rolling Y</th>
            <th>H2H LY</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{ConvertToCurrency(column["LW Sales"], 0)}</td>
              <td>{ConvertToCurrency(column["L4W Sales"], 0)}</td>
              <td>{ConvertToCurrency(column["Rolling Year Sales"], 0)}</td>
              <td>{ConvertToCurrency(column["H2H LY Sales"], 0)}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable4 = (
      <>
        <thead>
          <tr>
            <th>GP</th>
            <th>&lt;0%</th>
            <th>0%-25%</th>
            <th>25%-40%</th>
            <th>40%-55%</th>
            <th>55%+</th>
            <th></th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{column["Margin Band (Negative)"]}</td>
              <td>{column["Margin Band (0-25)"]}</td>
              <td>{column["Margin Band (25-40)"]}</td>
              <td>{column["Margin Band (40-55)"]}</td>
              <td>{column["Margin Band (55+)"]}</td>
              <td></td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable5 = (
      <>
        <thead>
          <tr>
            <th>Comp</th>
            <th>None</th>
            <th>20%+ Less</th>
            <th>0%-20% Less</th>
            <th>0%-20% More</th>
            <th>20%-40% More</th>
            <th>40%+ More</th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{column["Competitor (No Competitor)"]}</td>
              <td>{column["Competitor (20% + Cheaper)"]}</td>
              <td>{column["Competitor (0-20 % Cheaper)"]}</td>
              <td>{column["Competitor (0-20 % More)"]}</td>
              <td>{column["Competitor (20-40 % More)"]}</td>
              <td>{column["Competitor (40% + More)"]}</td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable6 = (
      <>
        <thead>
          <tr>
            <th>L4W Instock</th>
            <th>Never</th>
            <th>&lt;25%</th>
            <th>25%-50%</th>
            <th>50%-75%</th>
            <th>75%+</th>
            <th></th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{column["L4W Instock (Never Stocked)"]}</td>
              <td>{column["L4W Instock (0-25%)"]}</td>
              <td>{column["L4W Instock (25-50%)"]}</td>
              <td>{column["L4W Instock (50-75%)"]}</td>
              <td>{column["L4W Instock (75% +)"]}</td>
              <td></td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable7 = (
      <>
        <thead>
          <tr>
            <th>Inventory</th>
            <th>SOH Retail</th>
            <th>SOO Retail</th>
            <th>Committed Retail</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{ConvertToCurrency(column["SOH Retail"], 0)}</td>
              <td>{ConvertToCurrency(column["SOO Retail"], 0)}</td>
              <td>{ConvertToCurrency(column["Committed Retail"], 0)}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable8 = (
      <>
        <thead>
          <tr>
            <th>SOH WOC</th>
            <th>0-4</th>
            <th>4-8</th>
            <th>Normal</th>
            <th>52+</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{column["SOH Cover (0-4)"]}</td>
              <td>{column["SOH Cover (4-8)"]}</td>
              <td>{column["SOH Cover (Normal)"]}</td>
              <td>{column["SOH Cover (52+)"]}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable9 = (
      <>
        <thead>
          <tr>
            <th>Planning</th>
            <th>RR Fcst</th>
            <th>TA Fcst</th>
            <th>RR ITR</th>
            <th>Rolling Y ITR</th>
            <th>H2H+MD ITR</th>
          </tr>
        </thead>
        {SummaryList.map((column, index) => (
          <tbody>
            <tr>
              <td></td>
              <td>{ConvertToCurrency(column["RR Forecast Retail"], 0)}</td>
              <td>{ConvertToCurrency(column["Trading Forecast Retail"], 0)}</td>
              <td>{column["Commit Stockturn of RR Forecast Retail"]}</td>
              <td>{column["Commit Stockturn on Rolling Year Sales"]}</td>
              <td>{column["Commit Coverage of H2H Sales Plus MD(10%)"]}</td>
            </tr>
          </tbody>
        ))}
      </>
    );

    let SummaryTable = (
      <table className="summary-table">
        {SummaryTable1} {SummaryTable2} {SummaryTable3} {SummaryTable4} {SummaryTable5}{" "}
        {SummaryTable6} {SummaryTable7} {SummaryTable8} {SummaryTable9}
      </table>
    );

    return SummaryTable;
  };

  const renderSummaryCatCloseoutBody = () => {
    return SummaryCatCloseout.map((column, index) => (
      <tbody>
        <tr>
          <td>{column.Category}</td>
          <td>{ConvertToCurrency(column["Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column.SOH$, 0)}</td>
          <td>{ConvertToCurrency(column.SOO$, 0)}</td>
          <td>{ConvertToCurrency(column.Commit$, 0)}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryCatQuitBody = () => {
    return SummaryCatQuit.map((column, index) => (
      <tbody>
        <tr>
          <td>{column.Category}</td>
          <td>{ConvertToCurrency(column["Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column.SOH$, 0)}</td>
          <td>{ConvertToCurrency(column.SOO$, 0)}</td>
          <td>{ConvertToCurrency(column.Commit$, 0)}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryCatFOBBody = () => {
    return SummaryCatFOB.map((column, index) => (
      <tbody>
        <tr>
          <td>{column.Category}</td>
          <td>{ConvertToCurrency(column["Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column.SOH$, 0)}</td>
          <td>{ConvertToCurrency(column.SOO$, 0)}</td>
          <td>{ConvertToCurrency(column.Commit$, 0)}</td>
        </tr>
      </tbody>
    ));
  };

  const renderSummaryCatLocalBody = () => {
    return SummaryCatLocal.map((column, index) => (
      <tbody>
        <tr>
          <td>{column.Category}</td>
          <td>{ConvertToCurrency(column["Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Review Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column["H2H Trading Forecast$"], 0)}</td>
          <td>{ConvertToCurrency(column.SOH$, 0)}</td>
          <td>{ConvertToCurrency(column.SOO$, 0)}</td>
          <td>{ConvertToCurrency(column.Commit$, 0)}</td>
        </tr>
      </tbody>
    ));
  };

  const handleClick_Export = async () => {
    var workbook = new ExcelJS.Workbook();

    var sheet0 = workbook.addWorksheet("Summary", {
      views: [{ activeCell: "A1", showGridLines: true }],
    });

    var sheet1 = workbook.addWorksheet("Supplier Quote", {
      views: [{ activeCell: "A1", showGridLines: true }],
    });

    var sheet2 = workbook.addWorksheet("Top Items", {
      views: [{ activeCell: "A1", showGridLines: true }],
    });

    var sheet3 = workbook.addWorksheet("Stock Status", {
      views: [{ activeCell: "A1", showGridLines: true }],
    });

    var sheet4 = workbook.addWorksheet("Review Sheet", {
      views: [{ activeCell: "A1", showGridLines: true }],
    });

    if (sheet0 !== undefined) {
      if (SummaryList.length > 0) {
        let firstSummaryRowObj: SummaryMainObj = SummaryList[0];
        sheet0.columns = Object.keys(firstSummaryRowObj).map((key) => ({
          header: key,
          key: key,
        }));
        sheet0.getRow(1).alignment = { wrapText: true };
        sheet0.addRows(SummaryList);
        sheet0.properties.defaultColWidth = 15;
        sheet0.getRow(1).height = 30;
      }
    }

    if (sheet1 !== undefined) {
      const supplierQuoteArr: SupplierQuoteObj[] = await getSupplierQuote();
      if (supplierQuoteArr.length > 0) {
        let firstSupplierQuoteRowObj: SupplierQuoteObj = supplierQuoteArr[0];

        // set header information
        sheet1.columns = Object.keys(firstSupplierQuoteRowObj)
          .filter((key) => (IsImageIncluded ? true : key !== "Image" && key !== "Link"))
          .map((key) => ({
            header: key,
            key: key,
          }));

        sheet1.addRows(supplierQuoteArr, "i");
        if (IsImageIncluded) {
          sheet1.getCell("A2").value = '=IMAGE(B2,"Image Not Found",0)';
          sheet1.properties.defaultRowHeight = 30;
          sheet1.getColumn(2).hidden = true;
        }
        sheet1.properties.defaultColWidth = 15;
      }
    }

    if (sheet2 !== undefined) {
      const topItemsArr: TopItemsObj[] = await getTopItems();
      if (topItemsArr.length > 0) {
        let firstTopItemsRowObj: TopItemsObj = topItemsArr[0];

        sheet2.columns = Object.keys(firstTopItemsRowObj)
          .filter((key) => (IsImageIncluded ? true : key !== "Image" && key !== "Link"))
          .map((key) => ({
            header: key,
            key: key,
          }));

        sheet2.addRows(topItemsArr, "i");
        if (IsImageIncluded) {
          sheet2.getCell("A2").value = '=IMAGE(B2,"Image Not Found",0)';
          sheet2.properties.defaultRowHeight = 30;
          sheet2.getColumn(2).hidden = true;
        }
        // sheet2.getRow(1).height = 30;
        sheet2.getRow(1).alignment = { wrapText: true };
        sheet2.getColumn(4).width = 25;
        sheet2.getColumn(5).width = 20;
        // sheet2.properties.defaultColWidth = 15;
      }
    }

    if (sheet3 !== undefined) {
      const stockStatusArr: StockStatusObj[] = await getStockStatus();
      if (stockStatusArr.length > 0) {
        let firstStockStatusRowObj: StockStatusObj = stockStatusArr[0];

        sheet3.columns = Object.keys(firstStockStatusRowObj)
          .filter((key) => (IsImageIncluded ? true : key !== "Image" && key !== "Link"))
          .map((key) => ({
            header: key,
            key: key,
          }));

        sheet3.addRows(stockStatusArr, "i");
        if (IsImageIncluded) {
          sheet3.getCell("A2").value = '=IMAGE(B2,"Image Not Found",0)';
          sheet3.properties.defaultRowHeight = 30;
          sheet3.getColumn(2).hidden = true;
        }
        sheet3.properties.defaultColWidth = 15;
      }
    }

    if (sheet4 !== undefined) {
      const ReviewArr: ReviewObj[] = await getReview();
      if (ReviewArr.length > 0) {
        let firstReviewRowObj: ReviewObj = ReviewArr[0];

        sheet4.columns = Object.keys(firstReviewRowObj)
          .filter((key) => (IsImageIncluded ? true : key !== "Image" && key !== "Link"))
          .map((key) => ({
            header: key,
            key: key,
          }));

        sheet4.addRows(ReviewArr, "i");
        if (IsImageIncluded) {
          sheet4.getCell("A2").value = '=IMAGE(B2,"Image Not Found",0)';
          sheet4.properties.defaultRowHeight = 30;
          sheet4.getColumn(2).hidden = true;
        }
        sheet4.properties.defaultColWidth = 15;
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(blob, "ExportedItems.xlsx");
    });

    // handleClose();
  };

  const handleChange_ImageCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsImageIncluded(!IsImageIncluded);
  };

  return (
    <div>
      <Modal
        size="xl"
        show={show}
        onShow={handleShow}
        onHide={handleClose}
        onExited={handleExit}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="popup-container">
            {/* OPTION 1 - LONG TABLE */}
            {/* <div className="summary-table-container">
              <table className="summary-table">
                {renderSummaryMainHeader()}
                {renderSummaryMainBody()}
              </table>
            </div> */}
            {/* OPTION 2 - Multi Level Table */}
            {/* <div className="default-alignment">
              <table className="summary-table">
                {renderSummaryGeneralHeader()}
                {renderSummaryGeneralBody()}
              </table>
              <table className="summary-table">
                {renderSummaryPriceHeader()}
                {renderSummaryPriceBody()}
              </table>
              <table className="summary-table">
                {renderSummaryCompHeader()}
                {renderSummaryCompBody()}
              </table>
              <table className="summary-table">
                {renderSummaryInvHeader()}
                {renderSummaryInvBody()}
              </table>
              <table className="summary-table">
                {renderSummaryForecastHeader()}
                {renderSummaryForecastBody()}
              </table>
            </div> */}
            {/* OPTION 3 - Grouped Multi Level Table */}
            <div className="summary-table-container-main">{RenderSummaryTable()}</div>

            {SummaryCatCloseout.length === 0 ? (
              ""
            ) : (
              <>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="text-align-center">
                      <b>Closeout</b>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-scroll">
                      <table className="summary-table">
                        {renderSummaryCatHeader()}
                        {renderSummaryCatCloseoutBody()}
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}

            {SummaryCatQuit.length === 0 ? (
              ""
            ) : (
              <>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="text-align-center">
                      <b>Quit</b>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-scroll">
                      <table className="summary-table">
                        {renderSummaryCatHeader()}
                        {renderSummaryCatQuitBody()}
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}

            {SummaryCatFOB.length === 0 ? (
              ""
            ) : (
              <>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="text-align-center">
                      <b>FOB</b>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-scroll">
                      <table className="summary-table">
                        {renderSummaryCatHeader()}
                        {renderSummaryCatFOBBody()}
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
            {SummaryCatLocal.length === 0 ? (
              ""
            ) : (
              <>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="text-align-center">
                      <b>Local</b>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-scroll">
                      <table className="summary-table">
                        {renderSummaryCatHeader()}
                        {renderSummaryCatLocalBody()}
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
          </div>
          {/* <FormCheck
            type="checkbox"
            id={`default-checkbox-include-image`}
            label="include images"
            value="include images"
            data-item="include images"
            checked={IsImageIncluded}
            onChange={handleChange_ImageCheckbox}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="popup-button-container">
            <div>
              <Button onClick={handleClick_Export}>Export</Button>
            </div>
            <div>
              <Button onClick={handleClick_Close}>Close</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Popup;
