import React, { useEffect, SetStateAction, useState } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

interface ItemLookupObj {
  Source: string;
  Status: string;
  MatchedItem: string;
  Description: string;
  Supplier: string;
}

type PopupProps = {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  setBatchID: React.Dispatch<SetStateAction<string>>;
  setEnableCustomFilter: React.Dispatch<SetStateAction<boolean>>;
};

function Popup({ show, setShow, setBatchID, setEnableCustomFilter }: PopupProps) {
  const [lookupItems, setLookupItems] = useState<string>("");
  const [lookedUpItems, setLookedUpItems] = useState<ItemLookupObj[]>([]);
  const [showReturn, setShowReturn] = useState<boolean>(false);

  const [batchNo, setBatchNo] = useState<string>("");

  const handleClose = () => {
    setShowReturn(false);
    setShow(false);
  };
  const handleShow = () => {};

  const handleClick_Close = () => {
    if (showReturn === true) {
      setShowReturn(false);
      setLookupItems("");
    } else {
      handleClose();
    }
  };

  const handleClick_Lookup = async () => {
    if (showReturn === true) {
      applyFilter();
      setEnableCustomFilter(true);
      setLookupItems("");
      handleClose();
    } else {
      getLookup();
    }
  };

  const getLookup = async () => {
    const inputJSON: string = JSON.stringify([{ Items: lookupItems }]);

    const res = await runFetch(
      spURL_POST + "Portal_ItemList_CustomFilter_Lookup",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      console.log(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setBatchNo(spResponse.Table[0].BatchID);
        console.log(spResponse.Table[0].BatchID);
      }
      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setLookedUpItems(
          spResponse.Table1.map((item: ItemLookupObj) => ({
            Source: item.Source,
            Status: item.Status,
            MatchedItem: item.MatchedItem,
            Description: item.Description,
            Supplier: item.Supplier,
          }))
        );
        setShowReturn(true);
      }
    }
  };

  const applyFilter = () => {
    setBatchID(batchNo);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onShow={handleShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        {showReturn === true ? (
          <div className="custom-lookup-results-container">
            <table className="itemlist-table">
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Status</th>
                  <th>Matched Item</th>
                  <th>Description</th>
                  <th>Supplier</th>
                </tr>
              </thead>
              <tbody>
                {lookedUpItems.map((item, index) => (
                  <tr key={"tablerow-" + index}>
                    <td width={100}>{item.Source}</td>
                    <td width={100} className={item.Status == "NOT FOUND" ? "color-red" : ""}>
                      {item.Status}
                    </td>
                    <td width={100}>{item.MatchedItem}</td>
                    <td width={300}>{item.Description}</td>
                    <td width={200}>{item.Supplier}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="default-alignment">
            <p>Paste item identifiers below </p>
            <p>(1 item per row)</p>
            <textarea
              className="textarea-noresize"
              rows={8}
              cols={30}
              onChange={(e) => setLookupItems(e.target.value)}
            ></textarea>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="popup-button-container">
          <div>
            <Button onClick={handleClick_Close}>{showReturn === true ? "Back" : "Close"}</Button>
          </div>
          <div>
            <Button onClick={handleClick_Lookup}>{showReturn === true ? "Apply" : "Lookup"}</Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default Popup;
