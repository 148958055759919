import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { useDebouncedState } from "../hooks/useDebouncedState";

interface SearchBarProps {
  show: boolean;
  setSearchDescription: React.Dispatch<SetStateAction<string>>;
}

function SearchBar({ show, setSearchDescription }: SearchBarProps) {
  const refSearchBar = useRef<HTMLInputElement>(null);

  const [SearchInput, setSearchInput] = useState<string>("");
  useDebouncedState(SearchInput, setSearchDescription, 1000);

  useEffect(() => {
    if (refSearchBar.current !== null) {
      refSearchBar.current.value = "";
      setSearchDescription("");
    }
  }, [show]);

  return (
    <div className="searchbar-container">
      <input
        ref={refSearchBar}
        className={show ? "" : "hidden"}
        placeholder="Search description here..."
        onChange={(e) => {
          setSearchInput(e.target.value);
        }}
        type="text"
      />
    </div>
  );
}

export default SearchBar;
