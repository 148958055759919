import React, { SetStateAction } from "react";
import { Offcanvas } from "react-bootstrap";

type MyOffcanvasProps = {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  OffcanvasTitle: string;
  OffcanvasComponent: JSX.Element;
};

function MyOffcanvas({ show, setShow, OffcanvasTitle, OffcanvasComponent }: MyOffcanvasProps) {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Offcanvas
      show={true}
      onShow={() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0";
      }}
      onHide={handleClose}
      className={!show ? "hidden" : "slide-in"}
      backdropClassName={!show ? "hidden" : undefined}
      backdrop={true}
    >
      <Offcanvas.Header
        className={`offcanvas-header-container ${!show && "hidden"}`}
        closeButton
      >
        <Offcanvas.Title>{OffcanvasTitle}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{OffcanvasComponent}</Offcanvas.Body>
    </Offcanvas>
  );
}

export default MyOffcanvas;
